import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

function AddProjectModal(props) {
    const location = window.location;
    const [projectName, setProjectName] = useState('');


    const handleProjectNameChange = (e) => {
        setProjectName(e.target.value);
    };

    const handleSubmit = () => {
        props.onHide();

        let bodyFormData = new FormData();
        bodyFormData.append('nom', projectName);

        let headers = {
            headers: {
                "API-TOKEN": localStorage.getItem("token"),
            },
        };

        axios.post('https://de-lafontaine.ca/mealplanner/public/api/projects', bodyFormData,  headers )
            .then((response) => {
                setProjectName(''); // Réinitialisez le nom du projet

                console.log(response.data);
                // Appelez la fonction pour masquer le modal
                props.onHide();

                // Appelez la fonction pour ajouter le nouveau projet à la liste
                props.onProjectAdded(response.data);
                location.reload();

                // Affichez une alerte pour informer l'utilisateur que le projet a été ajouté
                alert('Le projet a été ajouté avec succès!');
            })
            .catch((error) => {
                console.error('Erreur lors de l\'envoi des données:', error);
            })
            .finally(setProjectName(''));
    };

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter un nouveau projet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="projectName">
                        <Form.Label>Nom du projet</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Entrez le nom du projet"
                            value={projectName}
                            onChange={handleProjectNameChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Enregistrer
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddProjectModal;
