import React, { useState, useEffect } from 'react';
import BlackHeart from '../../../assets/img/black-heart.png';
import PinkHeart from '../../../assets//img/pink-heart.png';

export default function Heart(props) {

    const [animation, setAnimation] = useState(0);
    const { like, toggle, handleClick, count } = props;

    useEffect(() => {
        const renderAnimations = () => {
            return toggle ? setAnimation(1) : setAnimation(0)
        }
        renderAnimations()
    }, [toggle])

    const renderHeart = () => {
        return like ? PinkHeart : BlackHeart;
    };



    const heartClass = like ? 'heart-btn heart-btn-liked' : 'heart-btn';

    return (
        <>
        <img
            className={`heart ${heartClass} ${animation === 1 ? 'heart-animation' : ''}`}
            onClick={() => handleClick()}
            src={renderHeart()}
            animation={animation}
            alt="Heart" />
            <p className="count-like">{count}</p>
        </>
    )
}