import React from "react";
import Navbar from "./incs/common/Navbar";
import Footer from "./incs/common/Footer";
import {useState, useEffect} from "react";
import axios from "axios";
import TriangleLoader from "./incs/loader/TriangleLoader";
import {Link} from "react-router-dom";


const Home = () => {

    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    useEffect(() => {
        const getArticles = (page) => {
            axios.get(`https://de-lafontaine.ca/mealplanner/public/api/articles/recent-max-four?page=${page}`)
                .then((response) => {
                    setArticles(response.data.data);
                    setTotalPages(response.data.last_page);
                })
                .catch((error) => {
                    console.log(error.response);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        getArticles(currentPage); // Load articles for the initial page
    }, [currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <Navbar/>
            <div className="home-container">
                <div className="parallax1">
                    <div className="heading">
                        <p>DevMaster Hubs</p>
                    </div>
                </div>
                <section id="about" className="text-center">
                    <h1 className="text-danger">Solutions technologiques pour une vie meilleure</h1>
                    <h4 className="mb-4">Nous croyons en la puissance de la programmation pour transformer le monde.</h4>

                    <div className="two-col">
                        <p>Chez DevMaster, nous sommes les maîtres de la programmation et de l'informatique. Notre passion pour la technologie nous pousse à repousser les limites de l'innovation et à créer des solutions numériques exceptionnelles.

                            Notre mission est de concevoir des logiciels et des systèmes informatiques qui répondent aux besoins les plus complexes de nos clients. Que vous soyez une entreprise à la recherche de solutions informatiques sur mesure ou un individu ayant une idée novatrice, nous sommes là pour vous aider à concrétiser votre vision.
                        </p>
                        <div className="mt-2 mb-4">
                            <button>Start for free</button>
                        </div>
                    </div>

                    <div className="two-col">
                        <iframe width="100%" height="315"
                        src="https://www.youtube.com/embed/6H7kkgvieLo?si=3UNbQXKoIMfdrjM4"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen/>
                    </div>

                </section>
                <div className="parallax2">
                    <div className="heading-sm">
                        Créez, rêvez, innovez
                    </div>
                </div>
                <section id="products" className="dark our-team">
                        <div className="container">
                            <h2 className="title-our-team">Our Lastest Post</h2>
                            <p className="subtitle-our-lastest-post pb-5 text-center">
                                Dans cette section, vous découvrirez les informations les plus récentes et passionnantes que j'ai à partager. Que ce soit des actualités brûlantes, des astuces pratiques, des réflexions profondes ou des moments de divertissement, ces derniers posts sont soigneusement sélectionnés pour vous tenir informé(e) et engagé(e). Alors, restez à l'affût pour ne rien manquer !
                            </p>
                            <ul className="row">
                                {isLoading ? (
                                    <div className="d-flex justify-content-center mt-5">
                                        <TriangleLoader/>
                                    </div>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className="container">
                                                <div className=" ms-3 row justify-content-center">
                                                    {articles.map((article) => (
                                                        <li key={article.id} className="col-12 col-md-6 col-lg-3">
                                                            <div className="mycard-block equal-hight">
                                                                <figure><img
                                                                    src={`https://de-lafontaine.ca/mealplanner/storage/app/public/images/${article.image}`}
                                                                    className="img-fluid" alt=""/></figure>
                                                                <h3><Link to={`/blog/${article.id}`}>{article.title}</Link></h3>
                                                                <p>{article.subtitle}</p>
                                                                <ul className="follow-us clearfix">
                                                                    <li>
                                                                        <Link
                                                                            className="btn btn-circle my-social-btn google"
                                                                            to={`/blog/${article.id}`}
                                                                        >
                                                                            <i className="fa-solid fa-magnifying-glass"></i>
                                                                        </Link>
                                                                    </li>
                                                                    <li><button className="btn btn-circle my-social-btn fb"><i
                                                                        className="fa fa-facebook "></i></button></li>
                                                                    <li><button className="btn btn-circle my-social-btn twitter"><i
                                                                        className="fa fa-twitter "></i></button></li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </div>

                                                <div className="row justify-content-center mt-3 pagination-container">
                                                    <nav aria-label="Page navigation">
                                                        <ul className="pagination">
                                                            {currentPage > 0 && ( // Conditionally render the Previous button
                                                                <li className={`page-item`}>
                                                                    <button
                                                                        className="page-link"
                                                                        onClick={() => handlePageChange(currentPage - 1)}
                                                                    >
                                                                        <i className="fa-solid fa-chevron-left"></i>
                                                                    </button>
                                                                </li>
                                                            )}
                                                            {Array.from({ length: totalPages }, (_, i) => (
                                                                <li
                                                                    className={`page-item ${i + 1 === currentPage ? "active" : ""}`}
                                                                    key={i}
                                                                >
                                                                    <button
                                                                        className="page-link"
                                                                        onClick={() => handlePageChange(i + 1)}
                                                                    >
                                                                        {i + 1}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                            {currentPage <= totalPages && ( // Conditionally render the Next button
                                                                <li className={`page-item`}>
                                                                    <button
                                                                        className="page-link"
                                                                        onClick={() => handlePageChange(currentPage + 1)}
                                                                    >
                                                                        <i className="fa-solid fa-chevron-right"></i>
                                                                    </button>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}




                            </ul>
                        </div>
                </section>
                <div className="parallax3">
                </div>
                <div id="questions" className="dark construire-container">
                    <div>
                        <h3 className="text-center">Construisez un avenir numérique avec nous</h3>
                    </div>
                    <div className="two-col">
                        <p>These aren't just soap products; they're nourishing moisturizers; perfect for
                            sensitive skin, psoriasis, and eczema. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error
                            repudiandae qui est minima aut ratione, vitae
                            doloremque voluptates at, temporibus ipsam? Blanditiis mollitia facilis natus harum vitae consequuntur.
                            Pariatur, possimus.
                        </p>
                        <button className="mt-2 mb-4">Start Trial</button>
                    </div>


                    <section className="form-container">
                        <p>Have questions before you sign up? Let us know down below!</p>
                        <form>
                            <div className="form-box"><input id="name" type="text" name="name" placeholder="Enter your name" required/>
                            </div>
                            <div className="form-box"><input id="email" type="text" name="email" placeholder="Enter your email" required/>
                            </div>

                            <div className="form-box"><input id="submit" type="submit" value="Submit"/></div>
                        </form>
                    </section>

                </div>
            </div>
            <div className="parallax4">
            </div>



            <div className="row m-3">
                <div className="col-md-6 col-lg-3">
                    <div className="overview-box mx-auto">
                        <div className="features-icons-icon d-flex">
                            <i className="android fa-brands fa-java fa-5x m-auto"></i>
                        </div>
                        <h5>Java</h5>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3">
                    <div className="overview-box mx-auto">
                        <div className="features-icons-icon d-flex">
                            <i className="fa-brands fa-php fa-5x html5 m-auto"></i>
                        </div>
                        <h5>PHP</h5>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3">
                    <div className="overview-box mx-auto">
                        <div className="features-icons-icon d-flex">
                            <i className="fa-brands fa-square-js fa-5x css3 m-auto"></i>
                        </div>
                        <h5>Javascript</h5>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3">
                    <div className="overview-box mx-auto">
                        <div className="features-icons-icon d-flex">
                            <i className="fa-brands fa-react fa-5x css3 m-auto"></i>
                        </div>
                        <h5>React</h5>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3">
                    <div className="overview-box mx-auto">
                        <div className="features-icons-icon d-flex">
                            <i className="fa fa-html5 fa-5x html5 m-auto"></i>
                        </div>
                        <h5>Html 5</h5>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="overview-box mx-auto">
                        <div className="features-icons-icon d-flex">
                            <i className="fa fa-css3 fa-5x css3 m-auto"></i>
                        </div>
                        <h5>CSS 3</h5>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="overview-box mx-auto">
                        <div className="features-icons-icon d-flex">
                            <i className="fa fa-android fa-5x android m-auto"></i>
                        </div>
                        <h5>Android</h5>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="overview-box mx-auto">
                        <div className="features-icons-icon d-flex">
                            <i className="fa-brands fa-wordpress fa-5x drupal m-auto"></i>
                        </div>
                        <h5>Wordpress</h5>
                    </div>
                </div>
            </div>


            <Footer/>
        </>
    );
};

export default Home;
