import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Navbar from "../../incs/common/Navbar";
import Axios from "axios";
import { useParams } from "react-router-dom";
import TriangleLoader from "../../incs/loader/TriangleLoader";
import SidebarProject from "../../incs/common/SideBarProject";
import DeleteModal from "../../incs/modal/DeleteModal";

const ProjectDetail = () => {
    const { slug } = useParams();
    const [redirect, setRedirect] = useState(false);
    const [project, setProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [newTask, setNewTask] = useState("");
    const projectName = project ? project.nom : "";
    const [updateList, setUpdateList] = useState(false);
    const [confirmAddTask, setConfirmAddTask] = useState("");
    const [commentFormVisible, setCommentFormVisible] = useState(false);
    const [taskStates, setTaskStates] = useState(Array.from({ length: 10 }, () => ({ showDeleteModal: false })
    )
    );
    const [showDeleteModal, setShowDeleteModal] = useState(false);


    const handleNewTaskChange = (event) => {
        setNewTask(event.target.value);
    }

    const handleSubmitNewTask = (event) => {
        event.preventDefault();

        let headers = {
            headers: {
                "API-TOKEN": localStorage.getItem("token"),
            },
        };

        if (localStorage.getItem("token")) {

            let formData = new FormData();
            formData.append("nom", newTask);
            formData.append("projet_id", project.id);

            Axios.post(
                `https://de-lafontaine.ca/mealplanner/public/api/tasks`,
                formData,
                headers
            )
            .then((response) => {
                setUpdateList(true);
                setNewTask("");
                setConfirmAddTask("Task added successfully");
                setTimeout(() => {
                    setConfirmAddTask("");

                }, 3000);

            })
            .catch((error) => {
                console.log(error.response);
            })
        }
    };

    const handleDeleteTask = (id, index) => {
        const updatedTaskStates = [...taskStates];
        updatedTaskStates[index].showDeleteModal = true;
        setTaskStates(updatedTaskStates);
    };

    const handleDeleteConfirm = (index) => {
        const updatedTaskStates = [...taskStates];
        updatedTaskStates[index].showDeleteModal = false;
        setTaskStates(updatedTaskStates);
    };

    const handleModalClose = (index) => {
        const updatedTaskStates = [...taskStates];
        updatedTaskStates[index].showDeleteModal = false;
        setTaskStates(updatedTaskStates);
    };



    useEffect(() => {
        let headers = {
            headers: {
                "API-TOKEN": localStorage.getItem("token"),
            },
        };

        if (localStorage.getItem("token")) {
            Axios.get(
                `https://de-lafontaine.ca/mealplanner/public/api/projectsBySLug/${slug}`,
                headers
            )
                .then((response) => {
                    setProject(response.data.projet);
                })
                .catch((error) => {
                    console.log(error.response);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setRedirect(true);
        }
    }, [slug, updateList, confirmAddTask]);

    if (redirect) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Navbar pageTitle={projectName}/>
            <div className="projects container-fluid my-2">
                {isLoading ? (
                    <div className="d-flex justify-content-center mt-5">
                        <TriangleLoader />
                    </div>
                ) : (
                    <div className={"mt-3 container-fluid"}>
                        {project && (
                            <div className={"row"}>
                                <div className="col-2 p-0 sidebar">
                                    <SidebarProject slug={slug}/>
                                </div>

                                <div className={"col-md-8"}>
                                    <form onSubmit={handleSubmitNewTask}>
                                        <div className="d-flex mb-2">
                                            <input
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter new task ..."
                                                required
                                                value={newTask}
                                                onChange={handleNewTaskChange}
                                            />
                                            <button type="submit" className="btn btn-success ms-2"><i className="fa-solid fa-plus"></i></button>
                                        </div>
                                    </form>
                                    {confirmAddTask && (
                                        <div className="alert alert-success">
                                            {confirmAddTask}
                                        </div>
                                    )}
                                    {project && project.task.length > 0 ? (
                                        project.task.map((task, index) => (
                                            <div className="d-flex flex-column" key={task.id}>
                                                <div className="d-flex align-items-center border-bottom border-danger">
                                                    <input
                                                        className="form-check-input todo-checkbox"
                                                        type="checkbox"
                                                    />
                                                    <div className="w-100">
                                                        <div className="ms-4 mt-3 d-flex w-100 align-items-center justify-content-between">
                                                            <div className="">{task.nom}</div>
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-sm btn-add-comments"
                                                            onClick={() => setCommentFormVisible(!commentFormVisible)}>
                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                    </button>
                                                    <button className="btn btn-sm">
                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm"
                                                        onClick={() => handleDeleteTask(task.id, index)}
                                                    >
                                                        <i className="fa fa-times"></i>
                                                    </button>
                                                </div>
                                                <div className="subtasks-container mt-2"></div>
                                                <DeleteModal
                                                    show={taskStates[index].showDeleteModal}
                                                    onHide={() => handleModalClose(index)}
                                                    onDelete={() => handleDeleteConfirm(index)}
                                                />

                                                {commentFormVisible && (
                                                    <div className="comment-form">
                                                        <textarea rows="2" cols="100"></textarea>
                                                        <button className="btn btn-sm">Ajouter un commentaire</button>
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <div className="alert alert-warning mt-3">
                                            La liste des tâches est vide.
                                        </div>
                                    )}
                                </div>
                                <div className={"col-md-2"}>

                                    <div className={"card"}>
                                        <div className="card-header">
                                            <h5 className="card-title text-center">Specs</h5>
                                            <p className="card-text">
                                                <i className="fa-solid fa-umbrella-beach me-2"></i>{project.date_debut}
                                            </p>
                                            <p className="card-text">
                                                <i className="fa-solid fa-triangle-exclamation me-2"></i> {project.date_fin ? project.date_fin : "No deadline"}
                                            </p>
                                            <p className="card-text">
                                                <i className="fa-solid fa-triangle-exclamation me-2"></i> {project.statut ? project.statut : "No status"}
                                            </p>
                                            <p className="card-text">
                                                <i className="fa-solid fa-list-check me-2"></i> {project.task.length} {project.task.length > 1 ? "tasks" : "task"}
                                            </p>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ProjectDetail;
