import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import JSIcon from "../../../assets/icons/JSIcon.png";
import TSIcon from "../../../assets/icons/TSIcon.png";

import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/solid";
import FLogo from "../../../assets/logos/f-low-resolution-logo-black-on-transparent-background.png";


const SidebarProject = (props) => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [projectList, setProjectList] = useState(true);
    const [showProjectsList, setShowProjectsList] = useState(true);
    const MyAppList = () => {
        const isLoggedIn = localStorage.getItem("token");

        return (
            <div className="left-list-group list-group bg-light ms-4">
                <Link to={`/projects/${props.slug}`} className="">
                    <img
                        src={JSIcon}
                        alt="Js Icon"
                        className="text-yellow_vs me-1"
                        style={{width: "20px"}}
                    />
                    Home
                </Link>
                <Link to={`/projects/task`} className="">
                    <img
                        src={TSIcon}
                        alt="Ts Icon"
                        className="text-yellow_vs me-1"
                        style={{width: "20px"}}
                    />
                    Tasks
                </Link>
                <Link to="#" className="">
                    <img
                        src={JSIcon}
                        alt="JS Icon"
                        className="text-yellow_vs me-1"
                        style={{width: "20px"}}
                    />
                    Groups
                </Link>
            </div>
        );
    };

    // Utilise useEffect pour mettre à jour l'heure toutes les secondes
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Nettoyage de l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <nav>
                <div
                    className="directory"
                    onClick={() => setShowProjectsList(!showProjectsList)}
                >
                    {showProjectsList ? (
                        <ChevronDownIcon className="mr-1" style={{width: "50px"}}/>
                    ) : (
                        <ChevronRightIcon className="mr-1" style={{width: "50px"}}/>
                    )}
                    Projects
                </div>
                <div className="" onClick={() => setProjectList(!projectList)}>
                    {projectList ? (
                        <ChevronDownIcon
                            className="mr-1  ms-2"
                            style={{width: "50px"}}
                        />
                    ) : (
                        <ChevronRightIcon
                            className="mr-1  ms-2"
                            style={{width: "50px"}}
                        />
                    )}
                    Your stuff
                </div>

                {projectList ? <MyAppList/> : null}
            </nav>
            <div className="container">
                <div className="row">
                    <div className="">
                        <hr/>
                        <img className="f-logo-sidebar-Project" src={FLogo} alt="logo" />
                        <div className="div-hours mt-3">
                            <h5 className="text-center"><span
                                className="badge bg-secondary">{currentTime.toLocaleTimeString()}</span></h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidebarProject;
