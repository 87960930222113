import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

function Authentification() {
    const { provider, token } = useParams();
    const [redirect, setRedirect] = useState(false);

    console.log(provider);
    console.log(token);


    useEffect(() => {
        if(!provider || !token) {
            setRedirect(true);
        }
        localStorage.setItem("token", token);

        axios.get(`https://de-lafontaine.ca/mealplanner/public/api/user/${token}`)
        .then((response) => {
            localStorage.setItem("userId", response.data.id);
            localStorage.setItem("userName", response.data.name);
            localStorage.setItem("userLoggedIn", "true");
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error.response);
        });
        setRedirect(true);

    }, [provider, token]);

    if(redirect) {
        return <Navigate to="https://super-app.de-lafontaine.ca"/>;
    }

    return (
        <div>
            <h1>Google Authentification</h1>
        </div>
    );
}

export default Authentification;
