import React from "react";
import { Link } from "react-router-dom";
import FLogo from "../../../assets/logos/f-low-resolution-logo-black-on-transparent-background.png";
import {useState} from "react";
import {Howl} from "howler";
import ClicSound from "../../../assets/mp3/sound1.wav";


function SimpleNavbar(props) {
    // Access the prop value using props.pageTitle
    const { pageTitle } = props;


    return (
        <>
            <nav className="navbar navbar-expand-lg fixed-top bg-primary border-bottom">
                <div className="container-fluid">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar"
                        aria-controls="offcanvasNavbar"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon "></span>
                    </button>

                    <h2 className="ms-1 mt-2 text-light">
                        <Link to="/">
                            <img className={"f-logo"} src={FLogo} alt="logo"/>
                        </Link>
                        {pageTitle}
                    </h2>

                    <div
                        className="offcanvas offcanvas-end"
                        tabIndex="-1"
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                    >
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                                <i className="fa-solid fa-skull-crossbones me-2 mt-1"></i>{" "}
                                DevMaster App
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 me-1">
                                <li className="nav-item">
                                    <Link
                                        className="btn btn-sm btn-outline-success me-3 mt-1"
                                        to="/"
                                    >
                                        <i className="fa-solid fa-tree-city"></i><span
                                        className="hide-desktop"> Home</span>
                                    </Link>
                                </li>
                                <li className="nav-item mt-1">
                                    <Link className="btn btn-sm btn-outline-dark" to="/register">
                                        Register
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default SimpleNavbar;
